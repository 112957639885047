<div>
  <div>
    <div style="display: block">
      <canvas baseChart
        [data]="polarAreaChartData"
        [labels]="polarAreaChartLabels"
        [legend]="polarAreaLegend"
        [chartType]="polarAreaChartType">
      </canvas>
    </div>
  </div>
</div>
