import {Component, OnInit} from '@angular/core';
import { AccountService } from './Services/Account/account.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements OnInit{

  constructor(private accountService:AccountService){

  }

  ngOnInit(): void {

   // this.accountService.login();
    
  }
  title = 'Azure Park';

  
}
