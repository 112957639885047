import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-password-boxed',
  templateUrl: './forgot-password-boxed.component.html',
  styles: []
})
export class ForgotPasswordBoxedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
