<div class="app-footer">
  <div class="app-footer__inner">
    <div class="app-footer-left">
      <span class="pl-2">Copyright {{date}} - <a href="https://www.iamdivakarkumar.com">Divakar Kumar</a> All Rights Reserved </span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      <a target="_blank" href="https://icons8.com/icon/9241/dinosaur">Dinosaur</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <a target="_blank" href="https://icons8.com/icon/dVhvC302nWdN/dinosaur">Dinosaur</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>
    </div>
    <div class="app-footer-right">
      <a class="btn btn-sm btn-primary mr-2" href="https://azpark.iamdivakarkumar.com">Homepage</a>
    </div>
  </div>
</div>
