<div [class]="'app-sidebar sidebar-shadow ' + (config$ | async).sidebarTheme" (mouseover)="sidebarHover()"
     (mouseout)="sidebarHover()">
  <div class="app-header__logo">
    <div class="logo-src"></div>
    <div class="header__pane ml-auto">
      <button type="button" class="hamburger close-sidebar-btn hamburger--elastic"
              [ngClass]="{'is-active' : globals.toggleSidebar}" (click)="toggleSidebar()">
        <span class="hamburger-box">
            <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
  </div>
  <div class="app-sidebar-content">
    <div class="app-sidebar-scroll">
      <perfect-scrollbar [autoPropagation]="true">
        <div class="v-sidebar-menu vsm-default">
          <div class="vsm-list">
            <ngb-accordion [destroyOnHide]="false" [closeOthers]="true" [activeIds]="extraParameter">
              <ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Azure Park</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="dashboardsMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-rocket"></i>
                    <span class="vsm-title">Tools</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item"><a routerLink="" routerLinkActive="active-item"
                                               [routerLinkActiveOptions]="{exact: true}" class="vsm-link"><span
                        class="vsm-title">Sessions</span></a>
                      </div>
                      <!--<div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/buttons-standard"
                           class="vsm-link"> <span
                          class="vsm-title">Notes</span></a>
                      </div>-->
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <!--<ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Open Source</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="elementsMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-diamond"></i>
                    <span class="vsm-title">Elements</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/buttons-standard"
                           class="vsm-link"> <span
                          class="vsm-title">Standard Buttons</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/dropdowns" class="vsm-link"> <span
                          class="vsm-title">Dropdowns</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/icons" class="vsm-link"><span
                          class="vsm-title">Icons</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/cards" class="vsm-link"><span
                          class="vsm-title">Cards</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/list-group" class="vsm-link"> <span
                          class="vsm-title">List Groups</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/elements/timeline" class="vsm-link"> <span
                          class="vsm-title">Timeline</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="componentsMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-car"></i>
                    <span class="vsm-title">Components</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/tabs" class="vsm-link"><span
                          class="vsm-title">Tabs</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/accordions" class="vsm-link"> <span
                          class="vsm-title">Accordions</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/modals" class="vsm-link"> <span
                          class="vsm-title">Modals</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/progress-bar" class="vsm-link"> <span
                          class="vsm-title">Progress Bar</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/tooltips-popovers"
                           class="vsm-link"> <span
                          class="vsm-title">Tooltips &amp; Popovers</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/carousel" class="vsm-link"> <span
                          class="vsm-title">Carousel</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/components/pagination" class="vsm-link"> <span
                          class="vsm-title">Pagination</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="formElementsMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-helm"></i>
                    <span class="vsm-title">Form Elements</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/forms/controls" class="vsm-link"><span
                          class="vsm-title">Controls</span></a>
                      </div>
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/forms/layouts" class="vsm-link"><span
                          class="vsm-title">Layouts</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>-->
              <!--<ngb-panel id="tablesMenu">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-safe"></i>
                    <span class="vsm-title">Cloud Design Patterns</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                                  <div class="vsm-item">
              <a routerLink="/cloud/ambassador" routerLinkActive="active-item" class="vsm-link">
                <span class="vsm-title">Ambassador</span>
              </a>
              <a routerLink="/cloud/anti-corruption" routerLinkActive="active-item" class="vsm-link">
                <span class="vsm-title">Anti-Corruption Layer</span>
              </a>
              <a routerLink="/cloud/request-reply" routerLinkActive="active-item" class="vsm-link">
                <span class="vsm-title">Asynchronous Request-Reply</span>
              </a>
              <a routerLink="/cloud/bff" routerLinkActive="active-item" class="vsm-link">
                <span class="vsm-title">Backends for Frontends</span>
              </a>
              <a routerLink="/cloud/bulkhead" routerLinkActive="active-item" class="vsm-link">
                <span class="vsm-title">Bulkhead</span>
              </a>
              <a routerLink="/cloud/cache-aside" routerLinkActive="active-item" class="vsm-link">
                <span class="vsm-title">Cache-Aside</span>
              </a>
              <a routerLink="/cloud/choreography" routerLinkActive="active-item" class="vsm-link">
                <span class="vsm-title">Choreography</span>
              </a>
              <a routerLink="/cloud/circuit-breaker" routerLinkActive="active-item" class="vsm-link">
                <span class="vsm-title">Circuit Breaker</span>
              </a>
              <a routerLink="/cloud/claim-check" routerLinkActive="active-item" class="vsm-link">
                <span class="vsm-title">Claim Check</span>
              </a>
            </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>-->
              <!--<ngb-panel>
                <ng-template ngbPanelHeader>
                  <div class="vsm-header">Dashboard Boxes</div>
                </ng-template>
              </ngb-panel>
              <ngb-panel id="pagesMenu3">
                <ng-template ngbPanelHeader>
                  <button ngbPanelToggle class="vsm-link">
                    <i class="vsm-icon pe-7s-graph2"></i>
                    <span class="vsm-title">Chart Boxes</span>
                    <i class="vsm-arrow"></i>
                  </button>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="vsm-dropdown">
                    <div class="vsm-list">
                      <div class="vsm-item">
                        <a routerLinkActive="active-item" routerLink="/widgets/chart-boxes-3" class="vsm-link">
                          <span class="vsm-title">Variation 3</span></a>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel> -->
            </ngb-accordion>
            <!--<div class="vsm-header">Cloud Design Patterns</div>-->

          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</div>
