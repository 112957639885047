import { Component, OnInit } from '@angular/core';
import { faTh, faCheck, faTrash, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { HttpClient } from '@angular/common/http';
import { faUsers, faPlus } from '@fortawesome/free-solid-svg-icons';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from 'src/app/Services/Sessions/session.service';
import { AzSession, AzUser } from 'src/app/Models/Session';
import { PubSubService } from 'src/app/Services/PubSub/pubsub.service';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/Services/Account/account.service';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
})
export class SessionsComponent implements OnInit {

  message:string;
  sessions:AzSession[];
  userName:string;
  currentUserId:string;
  faUsers = faUsers;
  faPlus = faPlus;
  faTh = faTh;
  faCheck = faCheck;
  faTrash = faTrash;
  faAngleDown = faAngleDown;
  sessionAnalytics:any;
  faAngleUp = faAngleUp;
  ws:WebSocket;

  heading = 'Sessions Dashboard';
  subheading = '';
  icon = 'pe-7s-speaker icon-gradient bg-tempting-azure';
  closeResult: string;
  slideConfig6 = {
    className: 'center',
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    adaptiveHeight: true,
    dots: true,
  };

  constructor(private modalService: NgbModal,private sessionService:SessionService,
    private accountService:AccountService,private router:Router )
  {
      
  }

  open(content) {
    this.modalService.open(content).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {

    this.getAllSessions();   

    this.currentUserId=this.accountService.UserDetails.clientPrincipal.userId;

  }

  getAllSessions()
  {
    this.sessionService.GetAllSessions().subscribe((x:AzSession[])=>{
      
      this.sessions=x;

      this.sessions.forEach(element => {

        if(element.audiences.some(x=>x.id==this.currentUserId))
        {
          console.log("is joined true",element.name);
          element.isJoined=true;
        }
      });

      console.log("audiences",[...new Set(this.sessions?.map(x=>x.audiences?.map(aud=>aud.id)))]);

      this.sessionAnalytics={
        totalSessions:this.sessions?.length,
        totalSpeakers:[...new Set(this.sessions?.map(x=>x.user?.id))]?.length,
        totalAudiences:[...new Set(this.sessions?.map(x=>x.audiences?.map(aud=>aud.id)))]?.length
      };
      console.log("from ng oninit ",this.sessions)
    });    
  }

  onJoin(session:AzSession)
  {   
    if(session.isPublic || session.isJoined)
    {
    var canAppend=true;
    var upsertSession=session;
    var user=new AzUser();
    user.id=this.accountService.UserDetails.clientPrincipal?.userId;
    user.name=this.accountService.UserDetails.clientPrincipal?.userDetails;
    if(upsertSession.audiences==null)
     upsertSession.audiences= [];
     upsertSession.audiences.forEach( audience=> {
      if(audience.id==user.id)
      {
        canAppend=false;
      }
    }); 
    if(canAppend)
    {
    upsertSession.audiences.push(user);
    }
    this.sessionService.SetSessionDetails(session).subscribe((x)=>{      
      this.router.navigateByUrl('/session/join-session',{state:session});    
    });    
  }
  else
  {
    this.sessionService.RequestApproval(session).subscribe((x)=>{
      console.log("requested",x);
    });
  }
  }

  onEnter(session:AzSession)
  {
    this.router.navigateByUrl('/session/start-session',{state:session});
  }

  onRequest(session:AzSession)
  {
    console.log("Requested to join "+session.id);
    this.sessionService.RequestApproval(session).subscribe((x)=>{
      console.log("requested",x);
    });
  }
 

}
