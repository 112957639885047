<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}}
        <div
          class="page-title-subheading">
          {{subheading}}
        </div>
      </div>
    </div>
    <div class="page-title-actions">
      <button type="button" class="btn-shadow mr-3 btn btn-dark" [routerLink]="['']">
        <fa-icon [icon]="faHome"></fa-icon>
      </button>
    </div>
  </div>
</div>
<div class="main-card mb-3 card">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">My Sessions</div>
  </div>
  <div class="table-responsive" style="max-height: 300px;">
    <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
      <thead>
      <tr>        
        <th class="text-center">Avatar</th>
        <th class="text-center">Speaker</th>
        <th class="text-center">Topic</th>
        <th class="text-center">Status</th>
        <th class="text-center">Session Date</th>
        <th class="text-center">Total Audience</th>
        <th class="text-center">Actions</th>
      </tr>
      </thead>
      <tbody>
        <tr *ngIf="!(createdSessions?.length > 0)">
          <td colspan="8">You have not created any sessions yet!</td>
        </tr>
      <tr *ngFor="let session of createdSessions">                
        <td class="text-center" style="width: 80px;">
          <img width="40" class="rounded-circle" src="./assets/images/avatars/avatar.png" alt="">
        </td>
        <td class="text-center"><a [routerLink]="">{{session.user.name}}</a></td>
        <td class="text-center"><a [routerLink]="">{{session.name | slice : 0:80 }}</a></td>
        <td class="text-center">
          <div *ngIf="session.isPublic" class="badge badge-pill badge-success">Public</div>
          <div *ngIf="!session.isPublic" class="badge badge-pill badge-danger">Private</div>
        </td>
        <td class="text-center">
                                        <span class="pr-2 opacity-6">
                                            <i class="fa fa-business-time"></i>
                                        </span>
          {{session.date}}
        </td>
        <td class="text-center" style="width: 200px;">
          <div class="widget-content p-0">
            <div class="widget-content-outer">
              <div class="widget-content-wrapper">
                <div class="widget-content-left pr-2">
                  <div class="widget-numbers fsize-1 text-danger">71%</div>
                </div>
                <div class="widget-content-right w-100">
                  <div class="progress-bar-xs progress">
                    <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="71" aria-valuemin="0"
                         aria-valuemax="100" style="width: 71%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">
          <div role="group" class="btn-group-sm btn-group">
            <button (click)="onEnter(session)"  class="btn-shadow btn btn-primary">Enter</button>
            <button (click)="onDelete(session.id)" class="btn-shadow btn btn-primary">Delete</button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>  
</div>

<div class="main-card mb-3 card">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">Joined Sessions</div>
  </div>
  <div class="table-responsive" style="max-height: 300px;">
    <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
      <thead>
      <tr>        
        <th class="text-center">Avatar</th>
        <th class="text-center">Speaker</th>
        <th class="text-center">Topic</th>
        <th class="text-center">Status</th>
        <th class="text-center">Session Date</th>
        <th class="text-center">Total Audience</th>
        <th class="text-center">Actions</th>
      </tr>
      </thead>
      <tbody>
        <tr *ngIf="!(joinedSessions?.length > 0)">
          <td colspan="8">You have not joined any sessions yet!</td>
        </tr>
      <tr *ngFor="let session of joinedSessions">               
        <td class="text-center" style="width: 80px;">
          <img width="40" class="rounded-circle" src="./assets/images/avatars/avatar.png" alt="">
        </td>
        <td class="text-center"><a [routerLink]="">{{session.user.name}}</a></td>
        <td class="text-center"><a [routerLink]="">{{session.name | slice : 0:80 }}</a></td>
        <td class="text-center">
          <div *ngIf="session.isPublic" class="badge badge-pill badge-success">Public</div>
          <div *ngIf="!session.isPublic" class="badge badge-pill badge-danger">Private</div>
        </td>
        <td class="text-center">
                                        <span class="pr-2 opacity-6">
                                            <i class="fa fa-business-time"></i>
                                        </span>
          {{session.date}}
        </td>
        <td class="text-center" style="width: 200px;">
          <div class="widget-content p-0">
            <div class="widget-content-outer">
              <div class="widget-content-wrapper">
                <div class="widget-content-left pr-2">
                  <div class="widget-numbers fsize-1 text-danger">71%</div>
                </div>
                <div class="widget-content-right w-100">
                  <div class="progress-bar-xs progress">
                    <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="71" aria-valuemin="0"
                         aria-valuemax="100" style="width: 71%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">
          <div role="group" class="btn-group-sm btn-group">
            <button (click)="onJoin(session)" class="btn-shadow btn btn-primary">Join</button>            
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>  
</div>