<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}}
        <div
          class="page-title-subheading">
          {{subheading}}
        </div>
      </div>
    </div>
    <div class="page-title-actions">
      <button type="button" class="btn-shadow mr-3 btn btn-dark" [routerLink]="['']">
        <fa-icon [icon]="faHome"></fa-icon>
      </button>
    </div>
  </div>
</div>
<div class="main-card mb-3 card">
  <div class="card-body"><h5 class="card-title">{{session.name}}</h5>
    <div class="row">
      <div class="col-md-6">        
          <select type="select" [(ngModel)]="selectedLanguage" (change)='onLanguageChange()' id="fromLangugaeSelect" name="fromLanguageSelect"
                                class="custom-select">
          <option value="en-US">English</option>
          <option *ngFor="let lang of languages" value="{{lang.code}}">{{lang.language}}</option>          
        </select></div>     
        <div class="col-md-6">
          <div role="group" class="btn-group-sm btn-group">
            <button (click)="onListen()"  class="btn-shadow btn btn-primary">Listen</button>  
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;      
            <button (click)="onLeave()"  class="btn-shadow btn btn-primary">Leave</button>        
          </div>
          </div>         
    </div>   
    <div style="margin-top: 10px;">
      <div class="position-relative"><textarea name="translations"
        id="translations"
        rows="5"                                                                                                          
        class="form-control">{{translatedText}}</textarea>      
</div>    
    </div>
  </div>
</div>