<div>
  <div>
    <div style="display: block">
      <canvas baseChart
        [datasets]="radarChartData"
        [options]="radarChartOptions"
        [labels]="radarChartLabels"
        [chartType]="radarChartType">
      </canvas>
    </div>
  </div>
</div>
