export class Speech{
    subscriptionKey:string;
    subscriptionRegion:string;    
    text:string;    
    isStarted:boolean;
    fromLanguage:string;
    fromLanguages:Language[];
    toLanguages:Language[];
}

export interface Language{
    language:string,
    code:string
}

export interface Caption
{
    text:string;
    translations:Translations
}

export interface Translations{
    userId:string;
    offset:string;
    sessionId:string;
    translations:{};
}

export class TranslationSwitchGroup
{
    sessionId:string;
    userId:string;
    fromLanguageCode:string;
    toLanguageCode:string;
}

export class TranslationLeaveGroup
{
    sessionId:string;
    userId:string;
    fromLanguageCode:string;    
}