import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from '../Account/account.service';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {

  constructor(private router:Router , private accountService:AccountService) { }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {

    if(localStorage.getItem('user')!=undefined && (JSON.parse(localStorage.getItem('user'))).clientPrincipal!=null)
    {
     return true;
    }

    return this.accountService.login().pipe(
      map(x=>
      {        

        console.log("response from login method: "+ JSON.stringify(x));
        
        localStorage.setItem('user', JSON.stringify(x));

        this.accountService.SetUserDetails();

        const user=this.accountService.UserDetails;
              
        console.log("clientprincipal: "+JSON.stringify(user));        

        if(user && user.clientPrincipal) {

          this.accountService.CheckIfInvited(user.clientPrincipal).subscribe((isInvited)=>{

            console.log("is invited",x);            

            if(!isInvited)
            {              
                this.accountService.SendInvite(user.clientPrincipal).subscribe((invite)=>{
                
                  console.log("invitations send",invite);
        
                });
              }
  
          });        

          return true;
        }        
        
        window.location.replace('/.auth/login/aad');

        return false;
      }
    ));

  }
}
