import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { AzSession } from 'src/app/Models/Session';
import { Caption, Language, Speech, TranslationLeaveGroup, TranslationSwitchGroup } from 'src/app/Models/Speech';
import { AccountService } from 'src/app/Services/Account/account.service';
import { PubSubService } from 'src/app/Services/PubSub/pubsub.service';
import { SpeechService } from 'src/app/Services/Speech/speech.service';

@Component({
  selector: 'app-session-join',
  templateUrl: './session-join.html',
})
export class JoinSessionsComponent implements OnInit {
  faHome = faHome;
  session: AzSession;
  languages: Language[];
  speechConfig:Speech;
  prevLanguage:string;
  ws:WebSocket;
  switchGroup:TranslationSwitchGroup;
  leaveGroup:TranslationLeaveGroup;
  translatedText:string;
  captions:Caption;
  testingArea:'test'
  speechRecognizer: any;
  selectedLanguage: any = "en-US";
  heading = 'Join Session';
  subheading = '';
  icon = 'pe-7s-speaker icon-gradient bg-tempting-azure';
  closeResult: string;

  constructor(private router: Router, private speechService: SpeechService,private webPubSubService:PubSubService,private accountService:AccountService) {
    this.session = this.router.getCurrentNavigation().extras.state as AzSession;
  }

  async ngOnInit() {
    this.translatedText="";
    console.log("join session" + JSON.stringify(this.session));
    this.getLanguages();
    await this.webPubSubService.createWebSocketConnection(this.onSessionMessage.bind(this));    
  }

  onSessionMessage(event)
  {
    console.log("triggered callback event");

    console.log(event);

    var eventData=JSON.parse(event.data);

    if(eventData.data!=undefined && eventData.data.from!="[System]")
    {
      console.log("message from pubsub",eventData.data.content);

      this.translatedText=eventData.data.content;
    }
    
  }

  onListen()
  {
    console.log("listening to session "+this.session.id+"-"+this.speechService.GetLanguageCode(this.selectedLanguage));

    this.prevLanguage= this.selectedLanguage;

    this.webPubSubService.AzParkWebSocket.send(
      JSON.stringify({
        type: 'event',
        event: 'joinsession',
        dataType: 'text',
        data: this.session.id+"-"+this.speechService.GetLanguageCode(this.selectedLanguage) ,
      })
    );
  }

  onLanguageChange()
  {
    this.switchGroup={
      toLanguageCode:this.speechService.GetLanguageCode(this.selectedLanguage),
      fromLanguageCode:this.speechService.GetLanguageCode(this.prevLanguage),
      sessionId:this.session.id,
      userId:this.accountService.UserDetails?.clientPrincipal?.userId,
    }

    console.log("swithGroup",this.switchGroup);

    this.speechService.ChangeLanguage(this.switchGroup).subscribe((x)=>{
      console.log(x);
    });

    this.prevLanguage=this.selectedLanguage;
  }

  getLanguages() {
    this.speechService.GetLanguages().subscribe((x) => {
      this.languages = x;
      console.log(JSON.stringify(this.languages));
    })
  }

  onLeave() {

    this.leaveGroup={
      fromLanguageCode:this.speechService.GetLanguageCode(this.selectedLanguage),
      sessionId:this.session.id,
      userId:this.accountService.UserDetails?.clientPrincipal?.userId,      
    }

    this.speechService.LeaveGroup(this.leaveGroup).subscribe((x)=>{
      this.router.navigateByUrl(''); 
    });

  }


}
