import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AzSession } from 'src/app/Models/Session';

@Injectable({
  providedIn: 'root'
})
export class RssFeedService {

  constructor(private http:HttpClient) { 

  }

  public GetRessFeeds()
  {
    return this.http.get<any>('/api/blogs').pipe(map(x => {
        return x;
    })); 

  }

}
