import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { CancellationReason, ResultReason } from 'microsoft-cognitiveservices-speech-sdk';
import { WebsocketMessageAdapter } from 'microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common.browser/WebsocketMessageAdapter';
import { AzSession } from 'src/app/Models/Session';
import { Caption, Speech } from 'src/app/Models/Speech';
import { AccountService } from 'src/app/Services/Account/account.service';
import { SpeechService } from 'src/app/Services/Speech/speech.service';
var evtExport = require("node_modules/microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common/Exports");
var browserExport = require("node_modules/microsoft-cognitiveservices-speech-sdk/distrib/lib/src/common.browser/Exports");
evtExport.Events.instance.attachListener(new browserExport.ConsoleLoggingListener(evtExport.EventType.Debug));

@Component({
  selector: 'app-session-start',
  templateUrl: './session-start.html',
})
export class StartSessionsComponent implements OnInit {
  faHome = faHome;
  session: AzSession;
  languages: any;
  speechConfig:Speech;
  speechText:string;
  captions:Caption;
  speechRecognizer: any;
  fromLanguage: any = "en-US";
  heading = 'Start Session';
  subheading = '';
  icon = 'pe-7s-speaker icon-gradient bg-tempting-azure';
  closeResult: string;

  constructor(private router: Router, private speechService: SpeechService,private accountService:AccountService) {
    this.session = this.router.getCurrentNavigation().extras.state as AzSession;
  }

  ngOnInit() {
    console.log("start session" + JSON.stringify(this.session));
    this.getLanguages();
  }

  getLanguages() {
    this.speechService.GetLanguages().subscribe((x) => {
      this.languages = x;
      console.log(JSON.stringify(this.languages));
    })
  }

  recognizerCallback(s, e) {
    const result = e.result
    const reason = ResultReason[result.reason]
    if (reason !== 'TranslatingSpeech' && reason !== 'TranslatedSpeech') {
      return
    }
    console.log("tamil translations"+result.translations.get("ta"));

    const sessionTranslations={
      userId: this.accountService.UserDetails?.clientPrincipal?.userId,
      offset:result.offset,
      sessionId:this.session.id,
      translations:{}
    }

    sessionTranslations.translations[this.speechService.GetLanguageCode(this.speechConfig.fromLanguage)] =result.text;

    for(let lang of this.speechConfig.toLanguages)
    {
      let langCode= this.speechService.GetLanguageCode(lang.code);
      let caption= result.translations.get(langCode);
      sessionTranslations.translations[langCode]=caption;
    }

    this.speechText= result.text;

    this.speechService.PublishTranslations(sessionTranslations).subscribe((x)=>{
      console.log(x);
    });

  };

   onStart() {
    
    this.speechService.SetSpeechConfig(this.fromLanguage,this.languages)

    this.speechConfig = this.speechService.GetSpeechConfig();

    this.speechService.GetToken(this.speechConfig.subscriptionRegion, this.speechConfig.subscriptionKey).subscribe((token) => {
      console.log("Issued token" + JSON.stringify(token));
      
      this.speechService.InitializeSpeechConfig(token);

      this.speechRecognizer = this.speechService.GetSpeechRecognizer;

      this.speechRecognizer.start;

      this.speechRecognizer.recognizing = this.speechRecognizer.recognized = this.recognizerCallback.bind(this);  
      
      this.speechRecognizer.startContinuousRecognitionAsync();

      /*this.speechRecognizer.recognizing = function (s, e) {
        var str = "(recognizing) Reason: " + this.speechRecognizer.ResultReason[e.result.reason] + " Text: " + e.result.text;
        console.log(str);
      };

      this.speechRecognizer.recognized = (reco: any, e: any) => {
        try {
          const res = e.result;
          console.log(e.result);
          if (e.result.reason === ResultReason.RecognizedSpeech) {
            console.log(res.text);
          }

          if (e.result.reason === ResultReason.NoMatch) {
            console.error("Unable to recognize speech");
          }
        } catch (error) {
          console.error("error", error);
        }
      };

      this.speechRecognizer.Canceled += (s, e) => {
        console.log("CANCELED: Reason=" + e.Reason);

        if (e.Reason == CancellationReason.Error) {
          console.log("CANCELED: ErrorCode=" + e.ErrorCode);
          console.log("CANCELED: ErrorDetails=" + e.ErrorDetails);
        }
      };
      this.speechRecognizer.SpeechEndDetected += (s, e) => {
        console.log("\nSpeech end detected event.");
      };

      this.speechRecognizer.SessionStarted += (s, e) => {
        console.log("\nSession started event.");
      };

      this.speechRecognizer.SessionStopped += (s, e) => {
        console.log("\nSession stopped event.");
      };

     /* this.speechRecognizer.recognizeOnceAsync(        
        function(result) { 
          console.log(result);
          this.speechRecognizer.close();
          this.speechRecognizer=undefined;
        },
        function(err){
          this.speechRecognizer.close();
          this.speechRecognizer=undefined;
          console.log(err);
        }
      )

      this.speechRecognizer.startContinuousRecognitionAsync(
        function (result: any) { console.log(result) },
        function (err: any) {
          console.trace("err - " + err);
        }
      );*/
    });
  }

  onStop() {

    this.speechRecognizer.stopContinuousRecognitionAsync(function(){
      this.speechRecognizer.close();
      this.speechRecognizer=undefined;
    },function(err){
      this.speechRecognizer.close();
      this.speechRecognizer=undefined;
    })

  }


}
