<div class="main-card mb-3 card">
  <div class="card-header">
    Related Blogs and Architectures
  </div>
  <div class="card-body">
<ngb-carousel *ngIf="blogs" [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators">

  <ng-template ngbSlide *ngFor="let blog of blogs">
    <a href="{{blog.link}}" target="_blank">
    <div style="background-color: black;">
    <img  height="400px" style="opacity: 0.7;filter:brightness(50%)" [src]="blog.image" width="100%" alt="image">    
    <div class="carousel-caption">
      <h3>{{blog.title}}</h3>
      <p>{{blog.description}}</p>
    </div>    
  </div>
</a>
  </ng-template>
  
</ngb-carousel>
</div>
