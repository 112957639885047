import { Component, OnInit } from '@angular/core';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { AzSession } from 'src/app/Models/Session';
import { AccountService } from 'src/app/Services/Account/account.service';
import { SessionService } from 'src/app/Services/Sessions/session.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-my-sessions',
  templateUrl: './session-my-sessions.component.html',
})
export class MySessionsComponent implements OnInit {
  
  createdSessions:AzSession[];
  joinedSessions:AzSession[];
  heading = 'My Sessions';
  faHome = faHome;
  subheading = '';
  user:any;
  icon = 'pe-7s-speaker icon-gradient bg-tempting-azure';

  constructor(private sessionService: SessionService,private accountService:AccountService, private route: ActivatedRoute,private router:Router) {

  }

  ngOnInit() {
    
    this.user = this.accountService.UserDetails;

    console.log("clientprincipal: " + JSON.stringify(this.user));

    this.getCreatedSessions(this.user?.clientPrincipal?.userId);

    this.getJoinedSessions(this.user?.clientPrincipal?.userId);
  }

  onEnter(session:AzSession){

    this.router.navigateByUrl('/session/start-session',{state:session});

  }

  onJoin(session:AzSession)
  {
    this.router.navigateByUrl('/session/join-session',{state:session});    
  }

  getCreatedSessions(userId:string) {

    this.sessionService.GetCreatedSessionsByUserId(userId).subscribe((x: AzSession[]) => {
      this.createdSessions = x;      
    });
  }

  onDelete(id)
  {
    this.sessionService.DeleteSession(id).subscribe(()=>{
        console.log("deleted"); 
        this.getCreatedSessions(this.user?.clientPrincipal?.userId);
    });
  }

  getJoinedSessions(userId:string) {

    this.sessionService.GetJoinedSessionsByUserId(userId).subscribe((x: AzSession[]) => {
      this.joinedSessions = x;      
    });
  }

}
