<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}}
        <div
          class="page-title-subheading">
          {{subheading}}
        </div>
      </div>
    </div>
    <div class="page-title-actions">
      <button type="button" class="btn-shadow mr-3 btn btn-dark" [routerLink]="['']">
        <fa-icon [icon]="faHome"></fa-icon>
      </button>
    </div>
  </div>
</div>
<div class="main-card mb-3 card">
  <div class="card-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="form-row">
        <div class="col-md-6">
          <div class="position-relative form-group"><label for="title">Session Title</label><input formControlName="title" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" name="title"                                                                                                  
                                                                                                    placeholder="Session Title"
                                                                                                    type="text"
                                                                                                    class="form-control">
                                                                                                    <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                                                                                      <div *ngIf="f.title.errors.required">Session title is required</div>
                                                                                                      <div *ngIf="f.title.errors.minlength">Session title must be at least 5 characters</div>
                                                                                                  </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="position-relative form-group"><label for="ispublic">Session Category</label>         
             <div class="position-relative form-check"><label class="form-check-label">
               <input formControlName="ispublic" [value]="true" name="ispublic"
            type="radio"
            class="form-check-input">
Public</label></div>
<div class="position-relative form-check"><label class="form-check-label"><input formControlName="ispublic" [value]="false" name="ispublic"
            type="radio"
            class="form-check-input">
Private</label></div>
        </div>
      </div>
      </div>
      <div class="position-relative form-group"><label for="description">Session Description</label><textarea [ngClass]="{ 'is-invalid': submitted && f.description.errors }" formControlName="description" name="description"
                                                                                                  id="description"
                                                                                                  rows="5"
                                                                                                  placeholder="Session descriptoin"                                                                                                  
                                                                                                  class="form-control"></textarea>
                                                                                                  <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                                                                                    <div *ngIf="f.description.errors.required">Session description is required</div>
                                                                                                    <div *ngIf="f.description.errors.minlength">Session description must be at least 10 characters</div>
                                                                                                </div>
      </div>     
      <div class="form-row">
        <div class="col-md-6">
          <div class="position-relative form-group"><label for="exampleCity">Session Date</label>
            <input type="datetime-local" [ngClass]="{ 'is-invalid': submitted && f.scheduledate.errors }"
            formControlName="scheduledate"
            class="form-control"
              [ngModel]="scheduledate | date:'yyyy-MM-ddTHH:mm'"/>
              <div *ngIf="submitted && f.scheduledate.errors" class="invalid-feedback">
                <div *ngIf="f.scheduledate.errors.required">Schedule date is required</div>                
            </div>
          </div>
          </div>
        <!--  <div class="col-md-6">
          <div class="position-relative form-group"><label for="exampleState">Avatar</label>             
            <div class="position-relative form-check">
            <input formControlName="ismale" [value]="true" name="ismale"
         type="radio"
         class="form-check-input">
         <img width="40" class="rounded-circle" src="./assets/images/avatars/avatar.png" alt="">        
         &nbsp;&nbsp;&nbsp;
        <input formControlName="ismale" [value]="false" name="ismale"
         type="radio"
         class="form-check-input">
         <img width="40" class="rounded-circle" src="./assets/images/avatars/avatar.png" alt="">      
          </div>
      </div>   
      </div>  -->
      </div>
      <button class="mt-2 btn btn-primary">Submit</button>
    </form>
  </div>
</div>