<div>
  <div>
    <div style="display: block">
      <canvas baseChart
        [datasets]="bubbleChartData"
        [options]="bubbleChartOptions"
        [colors]="bubbleChartColors"
        [legend]="bubbleChartLegend"
        [chartType]="bubbleChartType">
      </canvas>
    </div>
    <button mat-button mat-raised-button color="primary" (click)="randomize()">Update</button>
  </div>
</div>
