import {Component, OnInit} from '@angular/core';
import { User } from 'src/app/Models/User';
import { AccountService } from 'src/app/Services/Account/account.service';
import {ThemeOptions} from '../../../../../theme-options';

@Component({
  selector: 'app-user-box',
  templateUrl: './user-box.component.html',
})
export class UserBoxComponent implements OnInit {

  userName:string;

  user:User;

  constructor(public globals: ThemeOptions,private accountService:AccountService) {
  }

  ngOnInit() {
    
    console.log("subscribing account service user to update user name in sessions dashboard")

    this.accountService.user.subscribe(x => {
      if(x.clientPrincipal)
       this.userName=x.clientPrincipal.userDetails;
    });
  }

  logout()
  {
    localStorage.removeItem('user');
    window.location.replace('/.auth/logout');
  }

}
