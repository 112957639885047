import {Component, OnInit} from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import { Item } from 'src/app/Models/RssFeed';
import { RssFeedService } from 'src/app/Services/RssFeed/rss-feed.service';

@Component({
  selector: 'ngbd-carousel-navigation',
  templateUrl: './session-blog-architecture.html',
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})
export class NgbdCarouselNavigation implements OnInit {
  showNavigationArrows = true;
  showNavigationIndicators = true;  
  blogs:Item[];
  images = [1055, 194, 368].map((n) => `https://picsum.photos/id/${n}/900/500`);

  constructor(config: NgbCarouselConfig,private rssFeedService:RssFeedService) {
    // customize default values of carousels used by this component tree
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
    config.pauseOnHover=true;    
  }
  async ngOnInit() {   
    await this.rssFeedService.GetRessFeeds().subscribe(x=>{
      console.log(x);
      this.blogs=x;
    });
  }
}