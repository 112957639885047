import { Component, OnInit } from '@angular/core';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AzSession,AzUser } from 'src/app/Models/Session';
import { SessionService } from 'src/app/Services/Sessions/session.service';
import { AlertService } from 'src/app/Services/Alert/alert.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-session-add',
  templateUrl: './session-add.component.html',
})
export class AddSessionsComponent implements OnInit {

  message:string;
  userName:string;
  faHome = faHome;
  form : FormGroup;
  scheduledate: any;
  sessionModel: AzSession;
  submitted=false;

  heading = 'Create Session';
  subheading = '';
  icon = 'pe-7s-speaker icon-gradient bg-tempting-azure';
  closeResult: string;
  slideConfig6 = {
    className: 'center',
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    adaptiveHeight: true,
    dots: true,
  };

  constructor( private formBuilder: FormBuilder,   
    private router: Router,
    private sessionService:SessionService,
    private alertService: AlertService)
  {

  }

  ngOnInit() {
    this.form=this.formBuilder.group({
      title:['',[Validators.required,Validators.minLength(5)]],
      description:['',[Validators.required,Validators.minLength(10)]],
      ispublic:[true],
      scheduledate:[new Date().toDateString(),Validators.required]
    });
  }

  get f()
  {
    return this.form.controls;
  };

  onSubmit()
  {
    console.log(this.f);     
    this.submitted=true;

    if(this.form.invalid)
    return;

    this.sessionModel=new AzSession();
  
    this.sessionModel.id='';
    this.sessionModel.description=this.f.description.value;
    this.sessionModel.isPublic=this.f.ispublic.value;
    this.sessionModel.name=this.f.title.value;
    this.sessionModel.date=this.f.scheduledate.value;
    this.sessionModel.audiences=[];

    this.sessionService.SetSessionDetails(this.sessionModel)
    .pipe(first())
    .subscribe(
        data => {
            console.log(data);
            this.alertService.success('Update successful', { keepAfterRouteChange: true });
            this.router.navigate(['']);
        },
        error => {
            console.log(error);
            this.alertService.error(error);            
        });

  }

}
