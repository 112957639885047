import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AzSession } from 'src/app/Models/Session';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private http:HttpClient) { 

  }

  public SetSessionDetails(params:any)
  {
    return this.http.post('/api/session',params).pipe(map(x => {
        return x;
    })); 

  }

  public DeleteSession(id:string)
  {
    return this.http.delete('/api/session/'+id);
  }

  public GetAllSessions()
  {
    return this.http.get('/api/sessions');
  }
  public GetCreatedSessionsByUserId(userId:string)
  {
    return this.http.get('/api/sessions/created/user/'+userId);
  }

  public GetJoinedSessionsByUserId(userId:string)
  {
    return this.http.get('/api/sessions/joined/user/'+userId).pipe(map(x => {
      return x;
  })); 
  }
  public RequestApproval(session:AzSession)
  {
    return this.http.post('/api/session/request',session);
  }
  login() : any{
        
    return this.http.get('/.auth/me');  

  }

}
