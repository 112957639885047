import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PubSubService {
  ws:WebSocket;
  constructor(private http:HttpClient) { 

  }


public get AzParkWebSocket() :WebSocket
{
  return this.ws;
}

 public async createWebSocketConnection(callback)
 {
   await this.GetConnectionDetails().subscribe((response:any)=>{
     
     console.log("Connection details",response?.url);

     this.ws= new WebSocket(response?.url,"json.webpubsub.azure.v1");

     this.ws.onopen= (conn) =>{                
       console.log(conn);        

     };

     this.ws.onmessage=callback;

     this.ws.onclose = e => {        
       console.log("Client websocket closed.",e);
   }

   this.ws.onerror = e => {
       console.log("Client websocket error, check the Console window for details.",e);
   }

   });
 }
  public GetConnectionDetails()
  {
    return this.http.get('/api/connection');
  }

}
