<div class="app-page-title">
  <div class="page-title-wrapper">
    <div class="page-title-heading">
      <div class="page-title-icon">
        <i class="icon {{icon}}"></i>
      </div>
      <div>
        {{heading}}
        <div
          class="page-title-subheading">
          {{subheading}}
        </div>
      </div>
    </div>
    <div class="page-title-actions">
      <button type="button" class="btn-shadow mr-3 btn btn-dark" [routerLink]="['session/my-sessions']">
        <fa-icon [icon]="faUsers"></fa-icon>
      </button>
      <button type="button" class="btn-shadow d-inline-flex align-items-center btn btn-success" [routerLink]="['session/add']">
        <fa-icon [icon]="faPlus" class="mr-2"></fa-icon>
        Create New
      </button>
    </div>
  </div>
</div>
<div class="mb-3 card">
  <div class="card-header-tab card-header">
    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
      <i class="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
      Sessions Worldwide
    </div>
  </div>
  <div class="no-gutters row">
    <div class="col-sm-6 col-md-4 col-xl-4">
      <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-10 bg-warning"></div>
          <i class="pe-7s-album text-white opacity-8"></i></div>
        <div class="widget-chart-content">
          <div class="widget-subheading">Total Sessions</div>
          <div class="widget-numbers">{{sessionAnalytics?.totalSessions}}</div>          
        </div>
      </div>
      <div class="divider m-0 d-md-none d-sm-block"></div>
    </div>
    <div class="col-sm-6 col-md-4 col-xl-4">
      <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-9 bg-danger"></div>
          <i class="pe-7s-portfolio text-white"></i></div>
        <div class="widget-chart-content">
          <div class="widget-subheading">Total Speakers</div>
          <div class="widget-numbers"><span>{{sessionAnalytics?.totalSpeakers}}</span></div>          
        </div>
      </div>
      <div class="divider m-0 d-md-none d-sm-block"></div>
    </div>
    <div class="col-sm-12 col-md-4 col-xl-4">
      <div class="card no-shadow rm-border bg-transparent widget-chart text-left">
        <div class="icon-wrapper rounded-circle">
          <div class="icon-wrapper-bg opacity-9 bg-success"></div>
          <i class="pe-7s-light text-white"></i></div>
        <div class="widget-chart-content">
          <div class="widget-subheading">Total Audiences</div>
          <div class="widget-numbers text-success"><span>{{sessionAnalytics?.totalAudiences}}</span></div>          
        </div>
      </div>
    </div>
  </div>

  <div class="text-center d-block p-3 card-footer">
    <button class="btn-pill btn-shadow btn-wide fsize-1 btn btn-primary btn-lg">
                  <span class="mr-2 opacity-7">
                      <i class="icon icon-anim-pulse ion-ios-analytics-outline"></i>
                  </span>
      <span class="mr-1">View Complete Report</span>
    </button>
  </div>
</div>
<div class="main-card mb-3 card">
  <div class="card-header">
    <div class="card-header-title font-size-lg text-capitalize font-weight-normal">Live Sessions</div>
  </div>
  <div class="table-responsive" style="max-height: 300px;">
    <table class="align-middle text-truncate mb-0 table table-borderless table-hover">
      <thead>
      <tr>        
        <th class="text-center">Avatar</th>
        <th class="text-center">Speaker</th>
        <th class="text-center">Topic</th>
        <th class="text-center">Status</th>
        <th class="text-center">Session Date</th>
        <th class="text-center">Total Audience</th>
        <th class="text-center">Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let session of sessions">        
        <td class="text-center" style="width: 80px;">
          <img width="40" class="rounded-circle" src="./assets/images/avatars/avatar.png" alt="">
        </td>
        <td class="text-center"><a [routerLink]="">{{session.user.name}}</a></td>
        <td class="text-center"><a [routerLink]="">{{session.name | slice : 0:80 }}</a></td>
        <td class="text-center">
          <div *ngIf="session.isPublic" class="badge badge-pill badge-success">Public</div>
          <div *ngIf="!session.isPublic" class="badge badge-pill badge-danger">Private</div>
        </td>
        <td class="text-center">
                                        <span class="pr-2 opacity-6">
                                            <i class="fa fa-business-time"></i>
                                        </span>
          {{session.date}}
        </td>
        <td class="text-center" style="width: 200px;">
          <div class="widget-content p-0">
            <div class="widget-content-outer">
              <div class="widget-content-wrapper">
                <div class="widget-content-left pr-2">
                  <div class="widget-numbers fsize-1 text-danger">71%</div>
                </div>
                <div class="widget-content-right w-100">
                  <div class="progress-bar-xs progress">
                    <div class="progress-bar bg-danger" role="progressbar" aria-valuenow="71" aria-valuemin="0"
                         aria-valuemax="100" style="width: 71%;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
        <td class="text-center">
          <div role="group" class="btn-group-sm btn-group">
            <button *ngIf="session.user.id!=currentUserId &&!session.isPublic && session.isJoined" (click)="onJoin(session)" class="btn-shadow btn btn-primary">Join</button>
            <button *ngIf="session.user.id!=currentUserId &&!session.isPublic && !session.isJoined" (click)="onRequest(session)" class="btn-shadow btn btn-focus">Request</button>
            <button *ngIf="session.user.id!=currentUserId && session.isPublic" (click)="onJoin(session)" class="btn-shadow btn btn-primary">Join</button>
            <button *ngIf="session.user.id==currentUserId" (click)="onEnter(session)" class="btn-shadow btn btn-success">Enter</button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div class="d-block p-4 text-center card-footer">
    <button class="btn-pill btn-shadow btn-wide fsize-1 btn btn-dark btn-lg">
      <span class="mr-2 opacity-7">
          <i class="fa fa-cog fa-spin"></i>
      </span>
      <span class="mr-1">View Complete Sessions</span>
    </button>
  </div>
</div>
<ngbd-carousel-navigation></ngbd-carousel-navigation>

