<div class="h-100 bg-plum-plate bg-animation">
  <div class="d-flex h-100 justify-content-center align-items-center">
    <div class="mx-auto app-login-box col-md-8">
      <div class="app-logo-inverse mx-auto mb-3"></div>
      <div class="modal-dialog w-100 mx-auto">
        <div class="modal-content">
          <div class="modal-body">
            <div class="h5 modal-title text-center">
              <h4 class="mt-2">
                <div>Welcome back,</div>
                <span>Please sign in to your account below.</span></h4>
            </div>
            <div id="exampleInputGroup1" role="group" aria-describedby="exampleInputGroup1__BV_description_"
                 class="form-group">
              <div><input id="exampleInput1" type="email" placeholder="Enter email..." required="required"
                          aria-required="true" class="form-control"
                          aria-describedby="exampleInputGroup1__BV_description_">
                <small tabindex="-1" id="exampleInputGroup1__BV_description_" class="form-text text-muted">We'll never
                  share your email with anyone else.
                </small>
              </div>
            </div>
            <div id="exampleInputGroup2" role="group" class="form-group">
              <div><input id="exampleInput2" type="password" placeholder="Enter password..." required="required"
                          aria-required="true" class="form-control"></div>
            </div>
            <div class="custom-control custom-checkbox"><input id="exampleCheck" type="checkbox" name="check"
                                                               autocomplete="off" class="custom-control-input"
                                                               value="true"><label for="exampleCheck"
                                                                                   class="custom-control-label">
              Keep me logged in
            </label></div>
            <div class="divider"></div>
            <h6 class="mb-0">
              No account?
              <a [routerLink]="" class="text-primary">Sign up now</a></h6></div>
          <div class="modal-footer clearfix">
            <div class="float-left"><a [routerLink]="" class="btn-lg btn btn-link">Recover
              Password</a></div>
            <div class="float-right">
              <button type="button" class="btn btn-primary btn-lg">Login to Dashboard</button>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center text-white opacity-8 mt-3">
        Copyright © ArchitectUI 2019
      </div>
    </div>
  </div>
</div>
