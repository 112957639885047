<app-page-title [heading]="heading" [subheading]="subheading" [icon]="icon"></app-page-title>
<div class="row">
  <div class="col-md-6">
    <div class="card main-card mb-3">
      <div class="card-header">
        Doughnut
      </div>
      <div class="card-body">
        <app-doughnut-chart></app-doughnut-chart>
      </div>
    </div>
    <div class="card main-card mb-3">
      <div class="card-header">
        Radar
      </div>
      <div class="card-body">
        <app-radar-chart></app-radar-chart>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card main-card mb-3">
      <div class="card-header">
        Polar Area
      </div>
      <div class="card-body">
        <app-polar-area-chart></app-polar-area-chart>
      </div>
    </div>
    <div class="card main-card mb-3">
      <div class="card-header">
        Pie Chart
      </div>
      <div class="card-body">
        <app-pie-chart></app-pie-chart>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <div class="card main-card mb-3">
      <div class="card-header">
        Line Chart
      </div>
      <div class="card-body">
        <app-line-chart></app-line-chart>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card main-card mb-3">
      <div class="card-header">
        Dynamic Chart
      </div>
      <div class="card-body">
        <app-dynamic-chart></app-dynamic-chart>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card main-card mb-3">
      <div class="card-header">
        Bar Chart
      </div>
      <div class="card-body">
        <app-bar-chart></app-bar-chart>
      </div>
    </div>
  </div>
</div>
