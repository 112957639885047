import { Injectable } from '@angular/core';
import { Language, Speech, TranslationLeaveGroup, Translations, TranslationSwitchGroup } from 'src/app/Models/Speech';
import { AudioConfig,SpeechTranslationConfig,TranslationRecognizer,ResultReason, SpeechRecognizer } from 'microsoft-cognitiveservices-speech-sdk';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

//import './microsoft.cognitiveservices.speech.sdk.bundle';
//import { TranslationRecognizer } from 'microsoft-cognitiveservices-speech-sdk';
//declare const SpeechSDK:any;


@Injectable({
  providedIn: 'root'
})

export class SpeechService {

    config:Speech;
    speechConfig:any;
    speechRecognizer:any;    

    constructor(private http:HttpClient)
    {        
    
        this.SetSpeechConfig("en-US");                        
    }

    public async InitializeSpeechConfig(token?:string)
    {        
        let audioConfig=AudioConfig.fromDefaultMicrophoneInput();

        if(token!=null)
        {
            this.speechConfig= SpeechTranslationConfig.fromAuthorizationToken(token,
                this.config.subscriptionRegion);
        }
        else
        {
            this.speechConfig= SpeechTranslationConfig.fromSubscription(this.config.subscriptionKey,
                this.config.subscriptionRegion);
        }        
        
        this.speechConfig.speechRecognitionLanguage=this.config.fromLanguage;   
        
        for(let lang of this.GetSpeechConfig().toLanguages)
        {
            this.speechConfig.addTargetLanguage(this.GetLanguageCode(lang.code));
        }         

        this.speechRecognizer= new TranslationRecognizer(this.speechConfig,audioConfig);
        
    }

    public GetToken(subscriptionRegion:string,subscriptionKey:string)
    {
        let headerDict={
            'Content-type':'application/x-www-form-urlencoded',                         
            'Ocp-Apim-Subscription-Key' : subscriptionKey       
        }        

        return this.http.post("https://"+subscriptionRegion+".api.cognitive.microsoft.com/sts/v1.0/issueToken",JSON.stringify({}),{headers:headerDict,responseType:'text'});
    }

    public PublishTranslations(speechTranslations:Translations)
    {
        return this.http.post("https://func-webpubsub.azurewebsites.net/api/TranslationsToGroup",speechTranslations);        
    }

    public get GetSpeechRecognizer() : TranslationRecognizer
    {
        return this.speechRecognizer;
    }

    public GetLanguageCode(lang:string) :string
    {
        return lang.substr(0,2);
    }

    public SetSpeechConfig(languageCode?:string,languages?:any) 
    {
        this.config=new Speech();
        this.config.subscriptionKey="661a1be3dc9f42dea19a769afa2c1012";        
        this.config.subscriptionRegion="centralindia";
        this.config.fromLanguage=languageCode;      
        this.config.fromLanguages= languages;
        this.config.toLanguages=languages;
    }

    public ChangeLanguage(switchGroup:TranslationSwitchGroup)
    {
        return this.http.post("https://func-webpubsub.azurewebsites.net/api/SwitchGroup",switchGroup);        
    }
    public LeaveGroup(leaveGroup:TranslationLeaveGroup)
    {
        return this.http.post("https://func-webpubsub.azurewebsites.net/api/LeaveGroup",leaveGroup);        
    }
    public GetSpeechConfig()
    {
        return this.config;
    }
    public GetLanguages() : Observable<Language[]>
    {
        return this.http.get<Language[]>('/api/languages');
    }
    
}