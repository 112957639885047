import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject,Observable } from 'rxjs';
import { User, UserMetaData } from 'src/app/Models/User';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private userSubject: BehaviorSubject<User>;
  public user:Observable<User>;

  constructor(private http:HttpClient) { 

    this.userSubject=new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));

    this.user=this.userSubject.asObservable();

  }

  SendInvite(clientPrincipal: UserMetaData) {
    return this.http.post('/api/user/invite',clientPrincipal);
  }
  
  CheckIfInvited(clientPrincipal: UserMetaData) {
    return this.http.get('/api/user/'+clientPrincipal.userId+'/invited');
  }

  public get UserDetails(): User {

    return this.userSubject.value;

  }

  public DoHealthCheck()
  {
    return this.http.get('/api/healthcheck');
  }

  public SetUserDetails()
  {

    this.userSubject.next(JSON.parse(localStorage.getItem('user')));

  }

  login() : any{
        
    return this.http.get('/.auth/me');  

  }

  logout(){
    localStorage.removeItem('user');
    window.location.replace('/.auth/logout');
  }

}
