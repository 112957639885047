import { User } from "./User";

export class AzSession
{
    id:string;
    name:string;
    description:string;
    date:string;
    isPublic:boolean;
    sessionurl:string;
    requesturl:string;
    audiences:AzUser[];
    isJoined:boolean;
    user:AzUser;
}

export class AzUser
{
    id:string;
    name:string;
}